import React, { createContext, useReducer, Dispatch } from 'react'
import { indexReducer, userReducer, configReducer } from './Reducers'
import IState from '../types/model/IState'

const initialState = {
  user: undefined,
  appConfig: undefined,
  config: undefined,
}
const AppContext = createContext<{
  state: IState
  dispatch: Dispatch<any>
}>({
  state: initialState,
  dispatch: () => null,
})

const mainReducer = ({ user, appConfig, config }: IState, action: any) => ({
  appConfig: indexReducer(appConfig, action),
  user: userReducer(user, action),
  config: configReducer(config, action),
})

const AppProvider: React.FC<any> = ({ children, appName }) => {
  const [state, dispatch] = useReducer(mainReducer, {
    ...initialState,
    appConfig: {
      appName,
    },
  })

  return <AppContext.Provider value={{ state, dispatch }}>{children}</AppContext.Provider>
}

export { AppContext, AppProvider }
