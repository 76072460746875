import React, { useContext } from 'react'
import { ConfigProvider } from 'antd'
import App from 'antd/lib/app'
import { FeelEatThemeLight, FeelEatThemeDark } from '../../enums/feeleat-theme'
import { AppContext } from '../../store/context'
// import enUS from 'antd/es/locale/en_US'
import frFr from 'antd/locale/fr_FR'
import dayjs from 'dayjs'
import 'dayjs/locale/fr'
import updateLocale from 'dayjs/plugin/updateLocale'

dayjs.locale('fr')

/**
 * In french, Week Day start from 'Monday' but in FEEL EAT application says Week Day must be start from Sunday
 */
dayjs.extend(updateLocale)
dayjs.updateLocale('fr', {
  weekStart: 0,
})

const ApplicationBranding = ({ children }: { children: React.ReactNode }) => {
  const {
    state: { config: { isDarkTheme = false } = {} },
  } = useContext(AppContext)
  return (
    <ConfigProvider theme={isDarkTheme ? FeelEatThemeDark : FeelEatThemeLight} locale={frFr}>
      <App>{children}</App>
    </ConfigProvider>
  )
}

export default ApplicationBranding
