import React from 'react'
import Alert from 'antd/lib/alert'
import I18nOrJsx from './I18nOrJsx'
import IFeelEatAlertProps from '../../types/props/IFeelEatAlertProps'

const FeelEatAlert: React.FC<IFeelEatAlertProps> = ({
  message,
  type = 'info',
  showIcon = true,
  description,
  closable,
  action,
  style,
  className,
}) => {
  // TODO: Can be removed once all components are using typescript
  const validType = () => {
    if (!['success', 'info', 'warning', 'error'].includes(type)) {
      return 'error'
    }

    return type
  }

  return (
    <Alert
      message={<I18nOrJsx>{message}</I18nOrJsx>}
      closable={closable}
      description={<I18nOrJsx>{description}</I18nOrJsx>}
      type={validType()}
      showIcon={showIcon}
      action={action}
      style={style}
      className={className}
    />
  )
}

export default FeelEatAlert
