import App from 'antd/lib/app'
import { useEffect } from 'react'
import snackbarDispatcher from '../components/common/snackbarDispatcher'
import IGetNotificationsResponseParams from '../types/IGetNotificationsResponseParams'
import { useNavigate } from 'react-router-dom'

// This hook should be used once as early as possible, though below SnackbarProvider
function useSnackbarDispatcher(): void {
  const { notification } = App.useApp()
  const navigate = useNavigate()

  useEffect(() => {
    const unsubscribe = snackbarDispatcher?.subscribe(({ message, code, variant }: IGetNotificationsResponseParams) => {
      console.log('global ===>', { message, code, variant })
      switch (code) {
        case 'UNAUTHENTICATED':
          message = 'Session has been  expired. Please login again.'
      }

      if (message) {
        notification.info({ message, type: variant })
      }

      if (code === 'UNAUTHENTICATED') {
        navigate('/public/users/logout')
      }
    })

    return () => {
      unsubscribe?.()
    }
  }, [notification])
}

export default useSnackbarDispatcher
