import React from 'react'
import I18nLabel from '../../types/I18nLabel'
import getI18nLabel from '../../utils/getI18nLabel'

/**
 * React component wrapper for getI18nLabel
 */
const FeelEatI18n = ({ label }: { label?: I18nLabel }) => <>{getI18nLabel(label)}</>

export default FeelEatI18n
