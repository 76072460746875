import React from 'react'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'

const SingleColumnLayout = ({ children, ...props }) => (
  <Row>
    <Col {...props}>{children}</Col>
  </Row>
)

export default SingleColumnLayout
