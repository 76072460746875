import React from 'react'
import { isValidElement } from 'react'
import I18nLabel from '../../types/I18nLabel'
import getI18nLabel from '../../utils/getI18nLabel'

const I18nOrJsx = ({ children, wrap = true }: { children?: I18nLabel | JSX.Element; wrap?: boolean }) => (
  <div style={{ whiteSpace: wrap ? 'pre-wrap' : 'nowrap' }}>
    {isValidElement(children) ? children : getI18nLabel(children as I18nLabel)}
  </div>
)

export default I18nOrJsx
