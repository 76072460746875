import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import MainApp from './MainApp'
import reportWebVitals from './reportWebVitals'
import { registerLicense } from '@syncfusion/ej2-base'
import graphqlClient from './utils/graphqlClient'
import AppName from './types/AppName.enum'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

registerLicense('ORg4AjUWIQA/Gnt2UVhhQlVFfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5QdkBjW3pZcnFUQ2ZY')

root.render(
  <React.StrictMode>
    <MainApp appName={AppName.ADMIN_WEB_PORTAL} apolloClient={graphqlClient} />
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
