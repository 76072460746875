import type { ThemeConfig } from 'antd'
import theme from 'antd/lib/theme'

const { defaultAlgorithm, darkAlgorithm } = theme

const FeelEatThemeLight: ThemeConfig = {
  algorithm: [defaultAlgorithm],
  components: {
    Layout: {
      bodyBg: '#ffffff',
      siderBg: '#ffffff',
      headerBg: '#ffffff',
      headerColor: 'rgb(51, 65, 85)',
      headerPadding: '0 50px 0 0',
      footerBg: '#ffffff',
      footerPadding: '15px 50px',
      colorText: 'rgb(0, 21, 41)',
      algorithm: true,
      triggerBg: '#e9e9e9',
      triggerColor: '#000',
    },
    Menu: {
      itemActiveBg: '#e0e0e0',
      itemBg: '#ffffff',
      itemColor: '#000',
      itemSelectedBg: '#e0e0e0',
      itemHoverColor: '#000',
      itemSelectedColor: '#000',
      darkItemSelectedBg: '#ccc',
      darkItemBg: '#ffffff',
      darkItemColor: '#000',
      darkItemHoverBg: '#e0e0e0',
      darkItemHoverColor: '#000',
      popupBg: '#e0e0e0',
      itemBorderRadius: 0,
      colorText: '#000',
      collapsedIconSize: 18,
      groupTitleColor: '#000',
      horizontalItemSelectedBg: '#000',
      dropdownWidth: 180,
      algorithm: true,
    },
    Input: {
      activeBorderColor: 'rgb(126, 152, 188)',
      hoverBorderColor: 'rgb(126, 152, 188)',
      colorError: 'rgb(247, 0, 3)',
      colorErrorText: 'rgb(255, 0, 3)',
      colorPrimary: 'rgb(15, 23, 42)',
      lineHeight: 3,
      lineHeightLG: 3,
      colorBorder: '#ccc',
      controlHeight: 40,
      algorithm: true,
    },
    Form: {
      labelRequiredMarkColor: 'rgb(255, 0, 3)',
      colorError: 'rgb(255, 0, 3)',
      colorPrimary: 'rgb(15, 23, 42)',
      controlHeight: 40,
      colorSuccess: 'rgb(80, 161, 40)',
    },
    Button: {
      defaultBg: 'rgb(15, 23, 42)',
      defaultBorderColor: 'rgba(217, 217, 217, 0)',
      defaultColor: 'rgb(148, 163, 184)',
      controlHeight: 40,
    },
    Avatar: {
      colorTextPlaceholder: 'rgb(30, 41, 59)',
      colorTextLightSolid: 'rgb(148, 163, 184)',
    },
    Tabs: {
      cardHeight: 36,
      controlHeightLG: 36,
      fontSize: 12,
      colorBorder: 'rgb(210, 210, 210)',
      colorBorderSecondary: 'rgb(230, 229, 229)',
    },
    Table: {
      headerBg: 'rgba(0 ,0 ,0, 0.03)',
      fontSize: 12,
      lineHeight: 1,
      padding: 10,
      cellFontSize: 12,
      cellFontSizeMD: 12,
      cellFontSizeSM: 12,
      cellPaddingBlock: 8,
      cellPaddingBlockMD: 8,
      cellPaddingInline: 8,
      margin: 8,
      fontWeightStrong: 400,
    },
    Tag: {
      fontSize: 12,
      paddingXXS: 10,
      colorTextDescription: '#000',
      defaultColor: '#000',
      defaultBg: '#e0e0e0',
    },
    Drawer: {
      colorBgElevated: '#fff',
    },
    DatePicker: {
      controlHeight: 40,
      colorBorder: '#ccc',
    },
  },
  token: {
    colorPrimary: '#001529',
    colorInfo: '#001529',
    colorLink: '#1677ff',
    fontSize: 12,
  },
}
const FeelEatThemeDark: ThemeConfig = {
  algorithm: [darkAlgorithm],
  components: {
    Layout: {
      bodyBg: 'rgb(0, 21, 41)',
      siderBg: 'rgb(0, 11, 20)',
      headerBg: 'rgb(0, 21, 41)',
      headerColor: 'rgb(148, 163, 184)',
      headerPadding: '0 50px 0 0',
      footerBg: 'rgb(0, 21, 41)',
      footerPadding: '15px 50px',
      colorText: 'rgb(255, 255, 255)',
      algorithm: true,
    },
    Menu: {
      itemActiveBg: 'rgb(30, 41, 59)',
      itemBg: 'rgb(0, 11, 20)',
      itemColor: 'rgb(148, 163, 184)',
      itemSelectedBg: 'rgb(30, 41, 59)',
      itemHoverColor: 'rgb(255, 255, 255)',
      itemSelectedColor: 'rgb(255, 255, 255)',
      darkItemSelectedBg: 'rgb(30, 41, 59)',
      darkItemBg: 'rgb(0, 11, 20)',
      darkItemHoverBg: 'rgb(30, 41, 59)',
      darkItemHoverColor: 'rgb(255, 255, 255)',
      popupBg: 'rgb(0, 11, 20)',
      itemBorderRadius: 0,
      colorText: 'rgb(0, 11, 20)',
      collapsedIconSize: 18,
      groupTitleColor: 'rgb(0, 11, 20)',
      horizontalItemSelectedBg: 'rgb(0, 11, 20)',
      algorithm: true,
      dropdownWidth: 180,
    },
    Input: {
      activeBorderColor: 'rgb(126, 152, 188)',
      hoverBorderColor: 'rgb(126, 152, 188)',
      colorError: 'rgb(247, 0, 3)',
      colorErrorText: 'rgb(255, 0, 3)',
      colorPrimary: '#000',
      lineHeight: 3,
      lineHeightLG: 3,
      colorBorder: 'rgb(234, 234, 234)',
      controlHeight: 40,
      algorithm: true,
      activeBg: 'rgb(255, 255, 255)',
      hoverBg: 'rgb(234, 234, 234)',
      colorBgContainer: '#fff',
      colorIcon: '#000',
      colorText: '#000',
      colorTextPlaceholder: '#000',
    },
    Form: {
      labelRequiredMarkColor: 'rgb(255, 0, 3)',
      colorError: 'rgb(255, 0, 3)',
      colorPrimary: 'rgb(15, 23, 42)',
      controlHeight: 40,
      colorSuccess: 'rgb(80, 161, 40)',
    },
    Button: {
      defaultBg: 'rgb(2, 62, 117)',
      defaultBorderColor: 'rgb(0, 21, 41)',
      defaultColor: 'rgb(255, 255, 255)',
      defaultHoverColor: '#fff',
      controlHeight: 40,
      colorBgContainerDisabled: 'rgb(222, 238, 252)',
      colorTextDisabled: 'rgb(159, 185, 209)',
      colorBorder: 'rgb(159, 185, 209)',
    },
    Avatar: {
      colorTextPlaceholder: 'rgba(255,255,255, 0.3)',
      colorTextLightSolid: 'rgb(148, 163, 184)',
    },
    Tabs: {
      cardHeight: 36,
      controlHeightLG: 36,
      fontSize: 12,
      cardBg: 'rgb(2, 62, 117)',
      inkBarColor: 'rgb(255, 255, 255)',
      itemActiveColor: 'rgb(255, 255, 255)',
      itemHoverColor: 'rgb(255, 255, 255)',
      itemSelectedColor: 'rgb(2, 62, 117)',
      colorBgContainer: 'rgb(222, 238, 252)',
      colorBorder: 'rgb(2, 62, 117)',
      colorBorderSecondary: 'rgb(2, 62, 117)',
      colorText: 'rgb(255, 255, 255)',
    },
    Table: {
      headerBg: 'rgb(2, 62, 117)',
      fontSize: 12,
      lineHeight: 1,
      headerSortActiveBg: 'rgb(159, 185, 209)',
      headerSortHoverBg: 'rgb(222, 238, 252)',
      fixedHeaderSortActiveBg: 'rgb(222, 238, 252)',
      bodySortBg: 'rgb(2, 62, 117)',
      headerSplitColor: 'rgba(0, 0, 0, 0.4)',
      padding: 10,
      cellFontSize: 12,
      cellFontSizeMD: 12,
      cellFontSizeSM: 12,
      cellPaddingBlock: 8,
      cellPaddingBlockMD: 8,
      cellPaddingInline: 8,
      margin: 8,
      fontWeightStrong: 400,
      colorBgContainer: 'rgb(0, 21, 41)',
      colorText: '#ffffff',
      borderColor: 'rgba(0, 0, 0, 0.4)',
      headerColor: '#ffffff',
      filterDropdownBg: 'rgb(222, 238, 252)',
      filterDropdownMenuBg: 'rgb(222, 238, 252)',
    },
    Pagination: {
      itemActiveBg: 'rgb(159, 185, 209)',
      itemBg: '#fff',
      colorBorder: 'rgb(159, 185, 209)',
      colorText: '#fff',
      colorPrimary: 'rgb(2, 62, 117)',
      colorPrimaryHover: 'rgb(159, 185, 209)',
      colorPrimaryBorder: 'rgb(159, 185, 209)',
      colorBgContainer: 'rgb(0, 21, 41)',
      colorBgTextHover: 'rgba(0, 0, 0, 0.06)',
      itemLinkBg: 'rgb(159, 185, 209)',
      itemInputBg: 'rgb(159, 185, 209)',
    },
    Checkbox: {
      colorText: '#fff',
      colorBgContainer: 'rgb(159, 185, 209)',
    },
    Tag: {
      fontSize: 12,
      paddingXXS: 10,
      colorTextDescription: '#000',
      defaultColor: '#000',
      defaultBg: '#e0e0e0',
    },
    Drawer: {
      colorBgElevated: 'rgb(0, 11, 20)',
    },
    Card: {
      actionsBg: 'rgb(0, 21, 41)',
      headerBg: 'rgb(0, 21, 41)',
      colorBgContainer: 'rgb(0, 21, 41)',
      colorBorderSecondary: 'rgb(2, 62, 117)',
      colorFillAlter: 'rgb(0, 0, 0)',
      colorPrimary: 'rgb(0, 21, 41)',
      colorText: 'rgba(255, 255, 255, 0.8)',
      colorTextDescription: 'rgba(255, 255, 255, 0.8)',
      colorTextHeading: 'rgba(247, 247, 247, 0.8)',
    },
    DatePicker: {
      controlHeight: 40,
      colorBorder: 'rgb(241, 245, 249)',
    },
  },
  token: {
    colorPrimary: '#ffffff',
    colorInfo: '#ffffff',
    colorLink: '#ffffff',
    fontSize: 12,
  },
}

export { FeelEatThemeLight, FeelEatThemeDark }
