import React, { Suspense } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import ErrorBoundary from './ErrorBoundary'
import ApplicationLoading from './ApplicationLoading'
import LoggedInContainer from './LoggedInContainer'
import AppLayoutContainer from './AppLayoutContainer'

const LayoutComponent = React.lazy(() => import('./LayoutComponent'))
const PublicRoutes = React.lazy(() => import('./routes/public-routes/PublicRoutes'))

const AppRouter = () => {
  return (
    <BrowserRouter>
      <ErrorBoundary>
        <Routes>
          <Route
            path='/public/*'
            element={
              <AppLayoutContainer>
                <PublicRoutes />
              </AppLayoutContainer>
            }
          />
          <Route
            path='*'
            element={
              <AppLayoutContainer>
                <LoggedInContainer>
                  <Suspense fallback={<ApplicationLoading message='Loading FEEL EAT' />}>
                    <LayoutComponent />
                  </Suspense>
                </LoggedInContainer>
              </AppLayoutContainer>
            }
          />
        </Routes>
      </ErrorBoundary>
    </BrowserRouter>
  )
}

export default AppRouter
