import React from 'react'
import I18nOrJsx from './I18nOrJsx'
import I18nLabel from '../../types/I18nLabel'

const ChildrenOrLabel: React.FC<{
  children?: I18nLabel | JSX.Element
  label?: I18nLabel | JSX.Element
  wrap?: boolean
}> = ({ children, label, wrap = false }) => {
  if (children) {
    return <I18nOrJsx wrap={wrap}>{children}</I18nOrJsx>
  }

  if (label) {
    return <I18nOrJsx wrap={wrap}>{label}</I18nOrJsx>
  }

  return null
}

export default ChildrenOrLabel
