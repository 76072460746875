import React, { MouseEvent } from 'react'
import styled from 'styled-components'
import FeelEatButton from '../FeelEatButton'
import Icon from './Icon'
import IFeelEatIconParams from '../../../types/props/IFeelEatIconParams'
import FeelEatTextTooltip from '../FeelEatTextTooltip'

const Styled = styled.span`
  cursor: pointer;
  color: ${props => props.color};
`

const FeelEatIcon: React.FC<IFeelEatIconParams> = ({
  name,
  icon,
  title,
  onClick,
  disabled = false,
  button = false,
  stopPropagation = true,
  className,
  style,
  color,
  size,
  ...props
}) => {
  if (!icon) {
    return null
  }

  const actualOnClick = (e: MouseEvent<HTMLElement>) => {
    if (stopPropagation) {
      e.stopPropagation()
    }

    if (disabled) {
      return
    }

    if (onClick) {
      onClick(e)
    }
  }

  return (
    <Styled className={className} color={color}>
      <FeelEatTextTooltip title={title}>
        {!button ? (
          <span onClick={actualOnClick} data-cy={icon} {...props}>
            <Icon icon={icon} size={size} name={name} style={style} />
          </span>
        ) : (
          <FeelEatButton onClick={actualOnClick} data-cy={icon} {...props}>
            <Icon icon={icon} size={size} name={name} style={style} />
          </FeelEatButton>
        )}
      </FeelEatTextTooltip>
    </Styled>
  )
}

export default FeelEatIcon
