import React, { Suspense } from 'react'
import FeelEatSkeleton from './FeelEatSkeleton'
import ISkeletonSuspenseParams from '../../../types/props/ISkeletonSuspenseParams'
import SkeletonTypes from '../../../enums/skeleton-types'

const SkeletonSuspense: React.FC<ISkeletonSuspenseParams> = ({ children, title, type = SkeletonTypes.NORMAL }) => (
  <Suspense fallback={<FeelEatSkeleton title={title} type={type} />}>{children}</Suspense>
)

export default SkeletonSuspense
