import React from 'react'
import TFontAwesomeIconSize from '../../../types/TFontAwesomeIconSize'

const Icon = ({
  icon,
  size = 'fa-lg',
  style,
  name,
}: {
  icon: string
  size?: TFontAwesomeIconSize
  style?: any
  name?: string
}): JSX.Element => <i style={style} data-cy={name} className={`fad ${size} ${icon}`} />

export default Icon
