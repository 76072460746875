import React, { PropsWithChildren } from 'react'
import Layout from 'antd/lib/layout'
import styled from 'styled-components'
import SingleColumnLayout from './SingleColumnLayout'
import FeelEatFooter from '../../FeelEatFooter'
import FeelEatLogoColored from '../FeelEatLogoColored'

const { Content, Footer } = Layout

const Styled = styled.div`
  .header {
    margin: 0;
    text-align: center;
    background: #fff;
    border-bottom: #ededed;
    padding: 10px 0 20px 0;
  }

  .ant-layout {
    min-height: 100vh;
    background: rgb(241 245 249);
  }

  .ant-layout-content {
    margin: 25px 0 0 0;
  }

  .ant-layout-footer {
    margin-top: 0;
    background: #fff;
    border-top: #ededed;
    padding: 30px 0 30px 0;
    color: #000;
    text-align: center;
  }
`

const UserLayout: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Styled>
      <Layout>
        <div className='header'>
          <FeelEatLogoColored />
        </div>
        <Content>
          <SingleColumnLayout
            xs={{ span: 22, offset: 1 }}
            sm={{ span: 16, offset: 4 }}
            md={{ span: 12, offset: 6 }}
            lg={{ span: 8, offset: 8 }}
            xl={{ span: 6, offset: 9 }}
          >
            {children}
          </SingleColumnLayout>
        </Content>
        <Footer>
          <FeelEatFooter />
        </Footer>
      </Layout>
    </Styled>
  )
}

export default UserLayout
