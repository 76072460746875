import React, { PropsWithChildren } from 'react'
import { ErrorBoundary as SentryErrorBoundary, Scope } from '@sentry/react'
import ErrorBoundaryFallbackDev from './ErrorBoundaryFallbackDev'
import ErrorBoundaryFallbackProduction from './ErrorBoundaryFallbackProduction'
import SkeletonSuspense from './common/loading/SkeletonSuspense'

const ErrorBoundary: React.FC<
  PropsWithChildren<{
    beforeCapture?: (scope: Scope, error: Error | null, componentStack: string | null) => void
  }>
> = ({ children, beforeCapture }) => (
  <SentryErrorBoundary
    fallback={process.env.NODE_ENV === 'development' ? ErrorBoundaryFallbackDev : ErrorBoundaryFallbackProduction}
    beforeCapture={beforeCapture}
  >
    <SkeletonSuspense>{children}</SkeletonSuspense>
  </SentryErrorBoundary>
)

export default ErrorBoundary
