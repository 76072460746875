import { ErrorResponse } from '@apollo/client/link/error'
import { getGqlErrors, isNetworkError } from './getGraphqlError'
import IGetNotificationsResponseParams from '../types/IGetNotificationsResponseParams'

function getNotifications(error: ErrorResponse): IGetNotificationsResponseParams[] {
  if (isNetworkError(error)) {
    return [{ message: 'Check your network connection', variant: 'error' }]
  }

  return getGqlErrors(error).map(({ message, extensions, originalError }) => ({
    message: (message || originalError?.message || '') as string,
    code: extensions?.code as string,
    variant: 'error',
  }))
}

export default getNotifications
