import React from 'react'
import Card from 'antd/lib/card'
import FeelEatIcon from './icons/FeelEatIcon'
import BoxTitle from './BoxTitle'
import IBoxProps from '../../types/props/IBoxProps'

const Box: React.FC<IBoxProps> = ({ children, footer, onClose, extra, fullscreen, cover, title, padding, cy, ...props }) => {
  return (
    <Card
      {...props}
      title={<BoxTitle title={title} />}
      bodyStyle={fullscreen ? { display: 'none' } : { display: 'block', padding }}
      extra={
        (extra || onClose) && (
          <>
            {extra && extra}
            {onClose && <FeelEatIcon name='close-box' icon='fa-times' onClick={onClose} />}
          </>
        )
      }
      cover={cover || (fullscreen && children)}
      data-cy={cy}
    >
      {!fullscreen && children}
      {footer && <div className='panel-footer'>{footer}</div>}
    </Card>
  )
}

export default Box
