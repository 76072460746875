import React from 'react'
import UserLayout from './common/layout/UserLayout'
import FeelEatSkeleton from './common/loading/FeelEatSkeleton'
import SkeletonTypes from '../enums/skeleton-types'

const ApplicationLoading = ({ message = 'Loading data...' }: { message: string }) => (
  <UserLayout>
    <FeelEatSkeleton type={SkeletonTypes.PARAGRAPH} />
    <p>{message}</p>
  </UserLayout>
)

export default ApplicationLoading
