export const indexReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'SET_LAYOUT_USERS_ORDER':
      return {
        ...state,
        ...action.data,
      }
    default:
      return state
  }
}

export const configReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        isLoggedIn: true,
        isLoggedOut: false,
      }
    case 'LOGOUT':
      return {
        ...state,
        isLoggedIn: false,
        isLoggedOut: true,
      }
    case 'SET_LOW_NETWORK_QUALITY':
      return {
        ...state,
        ...action.data,
      }
    case 'SET_ACCESS_RESPONSE':
      return {
        ...state,
        accessResponse: action.data,
      }
    case 'SET_THEME':
      return {
        ...state,
        isDarkTheme: !state?.isDarkTheme || false,
      }
    default:
      return state
  }
}

export const userReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'ADD_USER':
      return {
        ...state,
        ...action.data,
      }
    case 'USER_JOINED_SESSION':
      return {
        ...state,
        ...action.data,
      }
    case 'USER_INSIDE_SESSION':
      return {
        ...state,
        ...action.data,
      }
    case 'USER_SET_DEVICES':
      return {
        ...state,
        ...action.data,
      }
    case 'CHANGE_LAYOUT':
      return {
        ...state,
        ...action.data,
      }
    case 'SET_ACTIVE_ROOM':
      return {
        ...state,
        ...action.data,
      }
    default:
      return state
  }
}
