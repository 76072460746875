import React from 'react'
import Skeleton from 'antd/lib/skeleton'
import Box from '../Box'
import SkeletonTypes from '../../../enums/skeleton-types'
import IFeelEatSkeletonProps from '../../../types/IFeelEatSkeletonProps'

const FeelEatSkeleton: React.FC<IFeelEatSkeletonProps> = ({ type = SkeletonTypes.NORMAL, active = true, title = 'Loading', style }) => {
  if (type === SkeletonTypes.NORMAL) {
    return (
      <Box title={title}>
        <Skeleton active={active} />
      </Box>
    )
  }

  if (type === SkeletonTypes.INPUT) {
    return <Skeleton.Input active={active} style={style} />
  }

  if (type === SkeletonTypes.BUTTON) {
    return <Skeleton.Button active={active} style={style} />
  }

  if (type === SkeletonTypes.IMAGE) {
    return <Skeleton.Image style={style || { width: 70, height: 70 }} />
  }

  return <Skeleton active={active} />
}

export default FeelEatSkeleton
