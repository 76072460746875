class Dispatcher<TAction> {
  // We only need a single subscriber, which is just a callback accepting an action
  private subscriber: ((value: TAction) => void) | undefined

  // If there is no subscriber, we store actions to dispatch them later
  private pendingActions: TAction[] = []

  public dispatch(action: TAction): void {
    if (this.subscriber) {
      // Upon dispatch, we simply call the subscriber...
      this.subscriber(action)
    } else {
      // ... or store the action if there is no subscriber
      this.pendingActions.push(action)
    }
  }

  public subscribe(subscriber: (value: TAction) => void): () => void {
    this.subscriber = subscriber

    // Upon subscription, we dispatch all pending actions
    this.pendingActions.forEach(action => {
      this.subscriber?.(action)
    })

    this.pendingActions = []

    // We return a callback to enable unsubscribing
    return () => {
      this.subscriber = undefined
    }
  }
}

export default Dispatcher
