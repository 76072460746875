import React, { ReactNode } from 'react'
import { Button } from 'antd'
import IButtonProps from '../../types/props/IButtonProps'
import ChildrenOrLabel from './ChildrenOrLabel'

interface IParams {
  isForm?: boolean
  stopPropagation?: boolean
  label?: string | JSX.Element
  icon?: ReactNode // not added to IButtonProps, as IFeelEatIconButton uses a different type
}

const FeelEatButton: React.FC<IParams & IButtonProps> = ({
  name,
  disabled,
  style,
  type = 'default',
  danger,
  onClick,
  target,
  id,
  children,
  isForm,
  stopPropagation = true,
  loading,
  active,
  label,
  size,
  className,
  ...props
}) => (
  <Button
    name={name}
    type={active ? 'primary' : type}
    danger={danger}
    className={className}
    disabled={disabled}
    onClick={e => {
      if (stopPropagation) {
        e.stopPropagation()
      }

      if (onClick) {
        onClick(e)
      }
    }}
    style={style}
    target={target}
    id={id}
    htmlType={isForm ? 'submit' : 'button'}
    data-cy={name}
    loading={loading}
    size={size}
    {...props}
  >
    <ChildrenOrLabel label={label}>{children}</ChildrenOrLabel>
  </Button>
)

export default FeelEatButton
