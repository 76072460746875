import I18nLabel from '../types/I18nLabel'

const getI18nLabel = (label?: I18nLabel) => {
  if (label === undefined) {
    return ''
  }

  if (typeof label === 'function') {
    return label()
  }

  if (!label) {
    return ''
  }

  return label
}

export default getI18nLabel
