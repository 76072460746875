import React from 'react'
import FeelEatAlert from './common/FeelEatAlert'

const ErrorBoundaryFallbackProduction = () => (
  <FeelEatAlert
    type='error'
    message='Something went wrong'
    description="We've logged this issue and will try to resolve it as soon as possible"
    showIcon
  />
)

export default ErrorBoundaryFallbackProduction
