import React from 'react'
import styled from 'styled-components'
import FeelEatButton from './FeelEatButton'
import Logo from '../../images/Logo-colored.png'

const Styled = styled.span`
  text-align: center;

  .logo {
    margin: 12px 16px;
  }

  // default logo size
  .logo img {
    width: 210px;
  }
`

const FeelEatLogoColored = () => {
  return (
    <Styled>
      <FeelEatButton className='logo' type='link'>
        <img src={Logo} alt='FEEL EAT Logo' />
      </FeelEatButton>
    </Styled>
  )
}

export default FeelEatLogoColored
