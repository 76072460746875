import { ErrorResponse } from '@apollo/client/link/error'
import getNotifications from './getNotifications'
import snackbarDispatcher from '../components/common/snackbarDispatcher'

interface OperationContext {
  disableErrorNotification?: boolean
}

function globalErrorHandler(error: ErrorResponse) {
  if (snackbarDispatcher) {
    const { disableErrorNotification } = error.operation.getContext() as OperationContext
    if (!disableErrorNotification) {
      getNotifications(error).forEach(notification => snackbarDispatcher?.dispatch(notification))
    }
  }
}

// This identity function is used for context typing
function context(operationContext: OperationContext): OperationContext {
  return operationContext
}

export { globalErrorHandler, context }
