import React from 'react'
import './App.css'
import { AppProvider } from './store/context'
import SkeletonSuspense from './components/common/loading/SkeletonSuspense'
import AppRouter from './components/AppRouter'
import { ApolloClient, ApolloProvider, NormalizedCacheObject } from '@apollo/client'
import ApplicationBranding from './components/common/ApplicationBranding'
import AppName from './types/AppName.enum'

interface IMainAppProps {
  appName: AppName
  apolloClient: ApolloClient<NormalizedCacheObject>
}

const MainApp = ({ appName, apolloClient }: IMainAppProps) => {
  return (
    <AppProvider appName={appName}>
      <ApplicationBranding>
        <ApolloProvider client={apolloClient}>
          <SkeletonSuspense>
            <AppRouter />
          </SkeletonSuspense>
        </ApolloProvider>
      </ApplicationBranding>
    </AppProvider>
  )
}

export default MainApp
