import { ServerError, ServerParseError, ApolloError } from '@apollo/client'
import { GraphQLError } from 'graphql'

function hasNetworkError(error: unknown): error is { networkError: Error | ServerError | ServerParseError } {
  return Boolean(error && typeof error === 'object' && (error as ApolloError).networkError)
}

function hasPopulatedGqlErrors(error: unknown): error is { graphQLErrors: ReadonlyArray<GraphQLError> } {
  return Boolean(
    error &&
      typeof error === 'object' &&
      Array.isArray((error as Partial<ApolloError>).graphQLErrors) &&
      (error as ApolloError).graphQLErrors.length,
  )
}

function hasUnpopulatedGqlErrors(error: unknown): error is {
  networkError: { result: { errors: ReadonlyArray<GraphQLError> } }
} {
  return Boolean(
    hasNetworkError(error) && 'result' in error.networkError && Object.keys(error.networkError.result).length,
    // error.networkError?.result?.errors.length &&
    // Array.isArray(error.networkError.result?.errors),
  )
}

function hasGqlErrors(error: unknown): boolean {
  return hasPopulatedGqlErrors(error) || hasUnpopulatedGqlErrors(error)
}

function isNetworkError(error: unknown): error is { networkError: Error | ServerError | ServerParseError } {
  return hasNetworkError(error) && !hasGqlErrors(error)
}

function getGqlErrors(error: unknown): GraphQLError[] {
  const result: GraphQLError[] = []

  if (hasPopulatedGqlErrors(error)) {
    result.push(...error.graphQLErrors)
  }

  if (hasUnpopulatedGqlErrors(error)) {
    result.push(...error.networkError.result.errors)
  }

  return result
}

export { getGqlErrors, isNetworkError }
