import React, { isValidElement } from 'react'
import I18nLabel from '../../types/I18nLabel'
import FeelEatI18n from './FeelEatI18n'
import IBoxTitleProps from '../../types/props/IBoxTitleProps'

const BoxTitle = ({ title }: IBoxTitleProps) => {
  if (!title) {
    return null
  }

  if (isValidElement(title)) {
    return title
  }

  return <FeelEatI18n label={title as I18nLabel} />
}

export default BoxTitle
