import React from 'react'
import useGetCurrentUserProfile from '../hooks/graphql/useGetCurrentUserProfile'
import ApplicationLoading from './ApplicationLoading'
import { useEffectOnce } from '../hooks/useEffectOnce'
import { Navigate } from 'react-router-dom'

const LoggedInContainer = ({ children }: { children: JSX.Element }) => {
  const { getUserProfile, loading, error } = useGetCurrentUserProfile()

  // we need to allow pass-through for the public routes, but we can't allow any other route than a /public route if not logged in
  useEffectOnce(() => {
    getUserProfile()
  })

  if (loading) {
    return <ApplicationLoading message='Loading FEEL EAT' />
  }

  if (error) {
    return <Navigate to='/public/users/logout' />
  }

  return children
}

export default LoggedInContainer
