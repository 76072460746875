import React from 'react'
import styled from 'styled-components'
import Tooltip from 'antd/lib/tooltip'
import ITooltipProps from '../../types/props/ITooltipProps'
import I18nOrJsx from './I18nOrJsx'

const Styled = styled.div`
  div,
  h1,
  h2,
  h3 {
    color: white;
  }
`
/**
 * A tooltip that appears when you hover the child element of this component
 *
 * title can be: a string, a i18n function, a react component, a richText object
 *
 * @param param0 t
 * @returns
 */
const FeelEatTextTooltip: React.FC<ITooltipProps> = ({ children, title, placement }) => {
  if (!title) {
    return <span>{children}</span> || null
  }

  return (
    <Tooltip
      title={
        <Styled>
          <I18nOrJsx>{title}</I18nOrJsx>
        </Styled>
      }
      placement={placement}
      data-cy={title}
    >
      <span>{children}</span>
    </Tooltip>
  )
}

export default FeelEatTextTooltip
